var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.affiliate_stores","resource":"/admin/ecommerce/affiliate-store","creatable":"","enterable":"","removable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showCreateModal = true}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.name}})],1)]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-break"},[_c('TMessageText',{attrs:{"value":item.address}})],1)]}},{key:"ecommerce_channel",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.ecommerce_channel ? item.ecommerce_channel.name : ''}})],1)]}},{key:"img_store",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-break"},[_c('TMessageText',{attrs:{"value":item.img_store,"messageOptions":{ truncate: 3 }}})],1)]}},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"ecommerce_channel-filter",fn:function(){return [_c('SSelectProductEc',{attrs:{"prependAll":"","value":_vm.filter.ecommerce_channel_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "ecommerce_channel_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"tel-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.tel},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "tel", $event)},_vm.filterChange]}})]},proxy:true},{key:"type-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.type},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "type", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('AffiliateStoreModal',{attrs:{"show":_vm.showCreateModal},on:{"update:show":function($event){_vm.showCreateModal=$event}}}),_c('AffiliateStoreModal',{attrs:{"show":_vm.showEditModal,"value":_vm.detail},on:{"updated":function($event){return _vm.filterChange()},"update:show":function($event){_vm.showEditModal=$event}}}),_c('CategoryAffiliateModal',{attrs:{"detail":_vm.detail,"show":_vm.showEditCategoryModal},on:{"updated":_vm.reselectAS,"update:show":function($event){_vm.showEditCategoryModal=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }